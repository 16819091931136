import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link
} from 'react-router-dom';

function App() {
  return (
      <div className="App">
        <header className="App-header">
          <Home />
        </header>
      </div>
  );
}

export default App;
