import * as React from 'react';
import axios from "axios";

import {makeStyles} from '@mui/material/styles';
import { experimentalStyled as styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


import BozTripCard from '../components/BozTripCard';
import { Typography } from '@mui/material';

import Image from '../images/background.jpg'; // Import using relative path
import BozTripCardLoader from '../components/BozTripCardLoader';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


var apiBase = "https://api.travelmanager.online/v1/";
var apiAllActivities = "activity/get?forceUpdate=true";


function sleep(milliseconds) {
  var start = new Date().getTime();
  for (var i = 0; i < 1e7; i++) {
    if ((new Date().getTime() - start) > milliseconds){
      break;
    }
  }
}
  
export default function AllTrips() {

    
    const [trips, setTrips] = React.useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const [loading, setLoading] = React.useState(true);


    React.useEffect(() => {
    
      axios.get(apiBase + apiAllActivities).then((response) => {
        setTrips(response.data);
        setLoading(false);
      });
    }, []);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        display: "flex",
      }));

  return (
        <div>
            {loading ? (
            <React.Fragment>


                <Grid container spacing={{ xs: 2, md: 3 }} >
                  
                      <Grid xs={12} sm={4} md={4} key="1" >
                        <BozTripCardLoader />
                      </Grid>

                      <Grid xs={12} sm={4} md={4} key="2" >
                        <BozTripCardLoader />
                      </Grid>

                      <Grid xs={12} sm={4} md={4} key="3" >
                        <BozTripCardLoader />
                      </Grid>

                      <Grid xs={12} sm={4} md={4} key="4" >
                        <BozTripCardLoader />
                      </Grid>
                      
                   
                </Grid>
            </React.Fragment>
            ):(
              
            <React.Fragment>
              
              {/*
              <Box component="span" sx={{p: 2, border: '1px dashed grey' }}>  
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel value="female" control={<Radio />} label="Female" />
                      <FormControlLabel value="male" control={<Radio />} label="Male" />
                      <FormControlLabel value="other" control={<Radio />} label="Other" />
                     
                    </RadioGroup>
                </FormControl>
            </Box> */}




                <Grid container spacing={{ xs: 2, md: 3 }} >
                    {trips.map((trip) => (
                      <Grid xs={12} sm={4} md={4} key={trip.title} >
                        <BozTripCard trip={trip }/>
                      </Grid>
                    ))}
                </Grid>
            </React.Fragment>
            )}
        </div>
    );
}