import * as React from 'react';

import {makeStyles} from '@mui/material/styles';
import { experimentalStyled as styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


import BozTripCard from '../components/BozTripCard';
import AllTrips from '../sections/AllTrips';
import AppOverview from '../sections/AppOverview';
import Header from '../sections/Header';
import HeaderAndNav from '../sections/HeaderAndNav';
import BottomNav from '../sections/BottomNav';
import HeaderWithProfile from '../sections/HeaderWithProfile';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link
  } from 'react-router-dom';
import TripFullDetails from '../sections/TripFullDetails';

  
export default function Home() {

  return (
    <React.Fragment>
        {/* <div style={{backgroundImage: `url(${'../images/bg5.png'})`, size: 'contain'}}> */}

        <div> 
        <Router>
            <Box sx={{height: '100vh'}}>
                <HeaderWithProfile />
                <Box sx ={{background: 'linear-gradient(to bottom , #ffffff, #ffffff)'}}> {/*backgroundColor="#c5d5cb" >*/}
                    <AppOverview />
                    <Routes>
                        <Route exact path="/" element={<AllTrips/>} />
                        <Route exact path="/:sid" element={<TripFullDetails/>} />
                        <Route exact path="/a/:aid" element={<TripFullDetails />} />
                    </Routes>
                </Box>
               {/* <BottomNav /> */}
            </Box>
        </Router>
        </div>
    </React.Fragment>
  );
}