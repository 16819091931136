import * as React from 'react';

import { experimentalStyled as styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue, grey, red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';


import Image from '../images/background.jpg'; // Import using relative path
import JoinActivity from '../controls/JoinActivity';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import Skeleton from '@mui/material/Skeleton';






const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  
export default function BozTripCardLoader() {

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
    
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        color: theme.palette.text.secondary,
      }));

  return (

    <React.Fragment>
     
     <Item>
            <Card sx={{ borderRadius: '16px', paddingBottom: 0}} >

                {/* REMOVING CARD HEADER FOR NOW
                <CardHeader
                    style={{ textAlign: 'left' }}
                    avatar={
                    <Avatar sx={{ bgcolor: blue[600] }} 
                        aria-label="recipe"
                        src={'https://res.cloudinary.com/travelmanage/image/upload/' + props.trip.createdByProfilePic}
                    />
                    }
                    action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                    }
                    title={props.trip.title}
                    subheader={'By ' + props.trip.createdByName}
                /> */}

                  
                <CardMedia
                    component="img"
                    image = {'../../images/blank.jpg'}
                />

               

<CardHeader 
                      style={{ textAlign: 'left', paddingBottom: 0.5, paddingTop: 0.5 }}
                    avatar={
                      <Skeleton variant="circular" width={40} height={40} />
                    }
                    action={
                        <IconButton aria-label="share">
                      </IconButton>
                        }
                    titleTypographyProps={{variant:'h6'}}
                    title=<Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    subheader=<Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                /> 

                <CardContent sx={{padding: 0}}>
                    <List dense={true} sx={{padding: 0}}>

                        <ListItem sx={{paddingBottom: 0}} >
                            <ListItemIcon>
                                <FmdGoodOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary=  <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />

                            />
                        </ListItem>

                        <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
                            <ListItemIcon>
                                <CalendarMonthOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary= <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />

                            />
                        </ListItem>

                        <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
                            <ListItemIcon>
                                <AccessTimeOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary= <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />

                            />
                        </ListItem>

                        <ListItem sx={{paddingTop: 0}} >
                            <ListItemIcon>
                                <Person2OutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary= <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />

                            />
                        </ListItem>

                       

                        
                    </List>
                </CardContent>

                <Divider />
    
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ textAlign: 'left' }}>
          <Typography paragraph>Details:</Typography>
          <Typography paragraph>
           Details here
          </Typography>
          <Typography paragraph>
            More Details here
          </Typography>
          <Typography paragraph>
           Some more details here
          </Typography>
          <Typography>
Even more details here           </Typography>
        </CardContent>
      </Collapse>
    </Card>
</Item>

    </React.Fragment>

    
  );
}