import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Avatar from '@mui/material/Avatar';
import { blue, grey, red } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Badge from '@mui/material/Badge';
import { Link } from 'react-router-dom';






const drawerWidth = 240;

const appBarStyle = {

    background : '#ffffff'
};

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


const styles = (theme) => ({
    toolbar: theme.mixins.toolbar,
  });

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 3,
      top: 3,
      padding: '0 4px',
    },
  }));

export default function HeaderWithProfile() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  

  return (
    <Box sx={{ display: 'flex'}}>
      <CssBaseline />

     
      <AppBar position="fixed" open={open}  style={{boxShadow:'1', background: '#ffffff' }}>
        <Toolbar disableGutters>
            <Toolbar sx={{flex: 1}} align="left" >
                <Avatar sx={{ bgcolor: blue[100] }} 
                        aria-label="recipe"
                        src="../../images/bozlogo.png"
                        size="small" 
                        component={Link} 
                        to="/"
                  />
            <Toolbar>
                    
                    <Typography variant="h6" noWrap component="div">
                        WeekendGPT
                    </Typography>
                </Toolbar>
            </Toolbar>

            {/*
            <Toolbar>
            <StyledBadge  variant="dot" >
                <NotificationsNoneIcon size="large" />
            </StyledBadge>
                <Box paddingLeft={2}/>
                <Avatar sx={{ bgcolor: grey[600] }} 
                        aria-label="recipe"
                />
            </Toolbar>
                  */}
        </Toolbar>
       
      </AppBar>

      <Toolbar style={{ background: 'transparent' }} /> {/* this empty toolbar ensures the appbar does not cover the content */}

      
      
    </Box>
  );
}
