import * as React from 'react';

import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue, grey, red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';


import Image from '../images/background.jpg'; // Import using relative path
import JoinActivity from '../controls/JoinActivity';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import JoinActivitySwitch from '../controls/JoinActivitySwitch';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import TripDetails from './TripDetails';
import { CardActionArea } from '@mui/material';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  
export default function BozTripCard(props) {

    const [expanded, setExpanded] = React.useState(false);


    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
    
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        color: theme.palette.text.secondary,
      }));

  return (

    <React.Fragment>
     
     <Item>
           {/* <Card  sx={{ borderRadius: '16px', backgroundImage: `url(${'../images/texture.jpg'})`, size: 'contain', backdropFilter: 'blur(40px)'}} > */}
           <Card sx={{ borderRadius: '16px', paddingBottom: 0, backgroundColor: "white"}} >

                
            <CardActionArea component={Link} to={'/a/' + props.trip.id}>
            
                
            <div style={{ position: "relative" }}>

                    <CardMedia
                        component="img"
                        image = {'https://res.cloudinary.com/travelmanage/image/upload/h_175,w_300/' + props.trip.destinationPic1}
                        alt={props.trip.title}
                    />
                        

    
                    <div style={{position: "absolute", color: "white",left: 0, top: 0, left: 0, right:0, transform: "translateX(-0%)"}}>
                        <Box sx ={{background: 'linear-gradient(to bottom , rgba(72,52,52,1), rgba(72,52,52,0))'}}> 
                            <Typography variant="caption">&nbsp;</Typography>
                            <Typography
                                variant="h5"
                                component="h5"
                                align="left"
                                sx={{ ml: 2, fontWeight: 'bold' }}>
                                {props.trip.title}
                            </Typography>
                            <Typography
                                variant="h6"
                                component="h6"
                                align="left"
                                sx={{ ml: 2, fontWeight: 'bold' }}>
                                {props.trip.startDate}
                            </Typography>
                            <Typography variant="caption" align="left" sx={{ ml: 2, fontWeight: 'bold' }}>&nbsp;</Typography>
                            </Box>
                        </div>
            </div>

            <CardHeader 
                    style={{ textAlign: 'left', paddingBottom: 2, paddingTop: 2, backgroundColor: "#483434" }}
                    avatar={
                    <Avatar sx={{ bgcolor: blue[600], width: 40, height: 40}} 
                        aria-label="recipe"
                        src={'https://res.cloudinary.com/travelmanage/image/upload/w_50,h_50,c_fill/' + props.trip.createdByProfilePic}       
                    />
                    }
                
                    titleTypographyProps={{variant:'h6', color: '#ffffff'}}
                    title={props.trip.createdByName}
                    subheaderTypographyProps={{color: '#ffffff'}}
                    spacing="5"
                    subheader={props.trip.hostDesignation}
                /> 
                

                <CardContent sx={{padding: 0, paddingBottom: 0, backgroundColor: "#483434"}}> 

                {/*

                    brown - 61481c
                    brown 2 - 483434
                    brown 3 - 362706
                
                */}



                    <List dense={true} sx={{padding: 0, paddingBottom: 0, color: '#ffffff'}}>

                        {/*
                        <ListItem sx={{paddingBottom: 0}} >
                            <ListItemIcon  sx={{color: '#ffffff'}}>
                                <FmdGoodOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={props.trip.memberCount}
                            />
                        </ListItem>
                       */}
{/*
                        <ListItem sx={{ paddingBottom: 0}}>
                            <ListItemIcon sx={{padding: 0, color: '#ffffff'}}>
                                <CalendarMonthOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={props.trip.startDate}
                            />
                        </ListItem>
                    */}

                        <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
                            <ListItemIcon sx={{color: '#ffffff'}}>
                                <AccessTimeOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={props.trip.displayTime}
                            />
                        </ListItem>

                        <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
                            <ListItemIcon sx={{color: '#ffffff'}}>
                                <HourglassTopRoundedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={props.trip.tripDuration}
                            />
                        </ListItem>

                        <ListItem sx={{paddingTop: 0}} >
                            <ListItemIcon sx={{color: '#ffffff'}}>
                                <PeopleAltOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                            primary={props.trip.memberCount == '1' ? props.trip.memberCount +' Person': props.trip.memberCount + ' People'} 
                            />
                         </ListItem>
                    </List>
                </CardContent>
</CardActionArea>
           
    </Card>
</Item>

    </React.Fragment>

    
  );
}