import * as React from 'react';

import {makeStyles} from '@mui/material/styles';
import { experimentalStyled as styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


import BozTripCard from '../components/BozTripCard';
import AllTrips from '../sections/AllTrips';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';





  
export default function AppOverview() {

 

  return (

    <React.Fragment>
        <div>
               
       <Box sx={{padding: 1}}>
       <Typography variant="h5" color="#362222"> </Typography>
       </Box>

        </div>

    </React.Fragment>

    
  );
}