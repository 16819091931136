import * as React from 'react';

import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue, grey, red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';


import Image from '../images/background.jpg'; // Import using relative path
import JoinActivity from '../controls/JoinActivity';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import JoinActivitySwitch from '../controls/JoinActivitySwitch';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import TripDetails from './TripDetails';
import { CardActionArea } from '@mui/material';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';


import Chip from '@mui/material/Chip';



const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  
export default function TripCardFull(props) {

    const [expanded, setExpanded] = React.useState(false);


    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
    
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        color: theme.palette.text.secondary,
      }));

  return (

    <React.Fragment>
     
     <Item>
           {/* <Card  sx={{ borderRadius: '16px', backgroundImage: `url(${'../images/texture.jpg'})`, size: 'contain', backdropFilter: 'blur(40px)'}} > */}
           <Card sx={{ borderRadius: '16px', paddingBottom: 0, backgroundColor: "white"}} >

                
            
                
            <div style={{ position: "relative" }}>

                    <CardMedia
                        component="img"
                        image = {'https://res.cloudinary.com/travelmanage/image/upload/c_fit,w_300/' + props.trip.destinationPic1}
                        alt={props.trip.title}
                    />
                        

    
                    <div style={{position: "absolute", color: "white",left: 0, top: 0, left: 0, right:0, transform: "translateX(-0%)"}}>
                    <Box sx ={{background: 'linear-gradient(to bottom , rgba(72,52,52,1), rgba(72,52,52,0))'}}> 
                        <Typography variant="caption">&nbsp;</Typography>
                        <Typography
                            variant="h5"
                            component="h5"
                            align="left"
                            sx={{ ml: 2, fontWeight: 'bold' }}>
                            {props.trip.title}
                        </Typography>
                        <Typography
                            variant="h6"
                            component="h6"
                            align="left"
                            sx={{ ml: 2, fontWeight: 'bold' }}>
                            {props.trip.startDate}
                        </Typography>
                        <Typography variant="caption" align="left" sx={{ ml: 2, fontWeight: 'bold' }}>&nbsp;</Typography>
                        </Box>
                    </div>
               
            </div>

{/* PROFILE */}
            <CardHeader 
                    style={{ textAlign: 'left', paddingBottom: 10, paddingTop: 2, backgroundColor: "#765555" }}
                    avatar={
                    <Avatar sx={{ bgcolor: blue[600], width: 40, height: 40}} 
                        aria-label="recipe"
                        src={'https://res.cloudinary.com/travelmanage/image/upload/w_50,h_50,c_fit/' + props.trip.createdByProfilePic}       
                    />
                    }
                
                    titleTypographyProps={{variant:'h6', color: '#ffffff'}}
                    title={props.trip.createdByName}
                    subheaderTypographyProps={{color: '#ffffff'}}
                    spacing="5"
                    subheader={props.trip.hostDesignation}
                /> 
  <Divider sx={{ borderBottomWidth: 3 }}/>           
        {/* WHEN */}
                <CardContent sx={{padding: 0, paddingBottom: 0, backgroundColor: "#f1ecec"}}> 
                    <List dense={true} sx={{padding: 0, paddingBottom: 0, color: '#202020'}}>
                    <ListItem sx={{paddingBottom: 0}}>
                          <ListItemIcon sx={{color: '#202020'}}>
                                <CalendarMonthOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={'When'}
                                primaryTypographyProps = {{variant:'h6'}}

                            />
                        </ListItem>

                        <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
                            
                            <ListItemText
                                primary={'Date: ' + props.trip.startDate + ' at '+ props.trip.displayTime}
                            />
                        </ListItem>
                       <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>                         
                            <ListItemText
                                primary={'Duration '+ props.trip.tripDuration}
                            />
                        </ListItem>                    
                    </List>
                </CardContent>
    <Divider sx={{ borderBottomWidth: 3 }}/>
          {/* WHERE */}
                <CardContent sx={{padding: 0, paddingBottom: 0, backgroundColor: "#f1ecec"}}> 
                    <List dense={true} sx={{padding: 0, paddingBottom: 0, color: '#202020'}}>
                    <ListItem sx={{paddingBottom: 0}}>
                            <ListItemIcon  sx={{color: '#202020'}}>
                                <FmdGoodOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Where'}
                                primaryTypographyProps = {{variant:'h6'}}

                            />
                        </ListItem>

                        
                        <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
                            <ListItemText
                                primary={props.trip.startLocation.name}
                            />
                        </ListItem>
                       <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>                         
                            <ListItemText
                                primary={props.trip.startLocation.address}
                            />
                        </ListItem> 
                        <ListItem sx={{paddingTop: 0, paddingBottom: 0}} button component="a" href={'https://maps.google.com/?q=' + props.trip.startLocation.lat + ',' + props.trip.startLocation.lng+'&query_place_id='+props.trip.startLocation.id}>
                        
                       {/* <ListItem sx={{paddingTop: 0, paddingBottom: 0}} button component="a" href={'https://goo.gl/maps/'+props.trip.startLocation.id}> */}


                     

                          <ListItemText primary="Click here for location on map" />
                        </ListItem>                 
                    </List>
                </CardContent>


    <Divider sx={{ borderBottomWidth: 3 }}/>

          {/* DETAILS */}
                 <CardContent sx={{padding: 0, paddingBottom: 0, backgroundColor: "#f1ecec"}}> 
                    <List dense={true} sx={{padding: 0, paddingBottom: 0, color: '#202020'}}>
                    <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
                            <ListItemIcon  sx={{color: '#202020'}}>
                                <DescriptionOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Details'}
                                primaryTypographyProps = {{variant:'h6'}}

                            />
                        </ListItem>
                        <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
                            <ListItemText sx={{whiteSpace: "pre-line"}}
                                primary={props.trip.hostMessage}
                            />
                        </ListItem>
                                      
                    </List>
                </CardContent>
  <Divider sx={{ borderBottomWidth: 3 }}/>

         {/* PARTICIPANTS */}
         <CardContent sx={{padding: 0, paddingBottom: 0, backgroundColor: "#f1ecec"}}> 
                    <List dense={true} sx={{padding: 0, paddingBottom: 0, color: '#303030'}}>
                    <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>

                          <ListItemIcon  sx={{color: '#202020'}}>
                              <PeopleAltOutlinedIcon />
                            </ListItemIcon>
                  
                            <ListItemText
                                primary={'Participants (' +props.trip.memberCount+')' }
                                primaryTypographyProps = {{variant:'h6'}}
                            />
                        </ListItem>

                        {props.trip.joinRequests.map((member) => (

                        <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
                            <Avatar sx={{ bgcolor: blue[600], width: 25, height: 25}} 
                            aria-label="recipe"
                            src={'https://res.cloudinary.com/travelmanage/image/upload/' + member.pic}       
                            />
                            <ListItemText
                                primary={member.name}
                                primaryTypographyProps = {{paddingLeft:2}}
                            />
                              <Divider />
                        </ListItem>

                        
                        
                        ))}

                       
                                      
                    </List>
                </CardContent>


              
           
    </Card>
</Item>

    </React.Fragment>

    
  );
}