import * as React from 'react';
import axios from "axios";

import {makeStyles} from '@mui/material/styles';
import { experimentalStyled as styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


import BozTripCard from '../components/BozTripCard';
import { Typography } from '@mui/material';

import Image from '../images/background.jpg'; // Import using relative path
import BozTripCardLoader from '../components/BozTripCardLoader';
import TripCardFull from '../components/TripCardFull';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";


var apiBase = "https://api.travelmanager.online/v1/";
var apiGetActivity = "activity/";
var apiGetShortData = "https://bozpro.in/v1/short/"


function sleep(milliseconds) {
  var start = new Date().getTime();
  for (var i = 0; i < 1e7; i++) {
    if ((new Date().getTime() - start) > milliseconds){
      break;
    }
  }
}
  
export default function TripFullDetails(props) {

    
    const [trip, setTrip] = React.useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    let { aid, sid } = useParams();
    const [activityid, setActivityId] = React.useState(null);

    React.useEffect(() => {
        setLoading(true);
        if (typeof aid !== 'undefined') {
            fetchActivity(aid);
        } else  if (typeof sid !== 'undefined') {
            let config = {
                headers: {
                    'x-ca-os-name': 'web',
                    'x-ca-os-version': '12',
                    'x-ca-app-version': '1.1',
                    'x-ca-app-version-code': '11',
                    'x-ca-created-by': '119'
                }
              }

            axios.get(apiGetShortData + sid, config).then((response) => {
                console.log(response.data);
                fetchActivity(response.data.activityId);
            });
        }
    }, [aid, sid]);

  
    const fetchActivity = (id) =>{
        axios.get(apiBase + apiGetActivity + id+'/get').then((response) => {
            console.log(response.data);
            setTrip(response.data);
            setLoading(false);
          });
    }


    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        display: "flex",
      }));

  return (
        <div>
            {loading ? (
            <React.Fragment>
                <Grid container spacing={{ xs: 2, md: 3 }} >
                  
                      <Grid xs={12} sm={4} md={4} key="1" >
                        <BozTripCardLoader />
                      </Grid>

                    
                      
                   
                </Grid>
            </React.Fragment>
            ):(
              
            <React.Fragment>
              
                        <TripCardFull trip={trip }/>
                
            </React.Fragment>
            )}
        </div>
    );
}